const $ = require("jQuery");

$(function () {
  // const Form = {
  //   isEntered: function () {
  //     let flag = true;

  //     $(".js-form-text.js-require").each(function (index, val) {
  //       let isEntered;

  //       isEntered = $(val).val() !== "";
  //       if (isEntered) return true;
  //       flag = false;
  //       return false;
  //     });

  //     return flag;
  //   },

  //   changeSubmit: function (flag) {
  //     if (flag) {
  //       $(".js-submit").addClass("_active").prop("disabled", false);
  //     } else {
  //       $(".js-submit").removeClass("_active").prop("disabled", true);
  //     }
  //   },

  //   onKeyup: function () {
  //     $(".js-form-text").keyup(function (e) {
  //       Form.changeSubmit(Form.isEntered());
  //     });
  //   },

  //   // onSubmit: function () {
  //   //   let form = $(".js-form");
  //   //   form.submit(function (e) {
  //   //     $.ajax({
  //   //       url: form.attr("action"),
  //   //       data: form.serialize(),
  //   //       type: "POST",
  //   //       dataType: "xml",
  //   //       statusCode: {
  //   //         0: function (e) {
  //   //           form.slideUp();
  //   //           $(".success").slideDown();
  //   //           console.log(e);
  //   //         },
  //   //         200: function () {
  //   //           form.slideUp();
  //   //           $(".error").slideDown();
  //   //           console.log(e);
  //   //         },
  //   //         400: function () {
  //   //           form.slideUp();
  //   //           $(".error").slideDown();
  //   //           console.log(e);
  //   //         },
  //   //       },
  //   //     });
  //   //     return false;
  //   //   });
  //   // },
  // };

  const Header = {
    initHeader: function () {
      $(window).on("scroll", function () {
        let scroll = $(window).scrollTop();

        if (scroll >= 300) {
          $(".js-header").addClass("_active");
        } else {
          $(".js-header").removeClass("_active");
        }
      });
    },
    initNav: function () {
      $(".js-link").on("click", function (e) {
        let href = $(this).attr("href");
        let position = $(href).offset().top - $("#header").innerHeight();
        $("html,body").animate({ scrollTop: position }, 1000, "swing");
      });
    },
  };

  const Drawer = {
    initDrawer: function () {
      $(".js-dlink").on("click", function () {
        $(".js-drawer").toggleClass("_active");
        $("body").toggleClass("_active");
        return false;
      });
    },
  };

  const Load = {
    initLoad: function () {
      $(document).ready(function (e) {
        $(".js-overlay").addClass("_disabled");
        $("body").addClass("_active");
      });
    },
  };

  // Form.onKeyup();
  Header.initHeader();
  Header.initNav();
  Drawer.initDrawer();
  Load.initLoad();
});
